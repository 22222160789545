"use strict";

var currentScript = document.currentScript;
var advertiser_key = document.currentScript.getAttribute('advertiser_key');
var click_id_param_name = document.currentScript.getAttribute('click_id_param_name');
var conversion_page_param = document.currentScript.getAttribute('conversion_page');
document.addEventListener("DOMContentLoaded", function () {
  if (window.location.href.includes(conversion_page_param)) {
    conversionPage();
  } else {
    landingPage();
  }
  function conversionPage() {
    var click_id_value;
    if (localStorage) {
      click_id_value = localStorage.getItem(click_id_param_name);
    }
    if (!click_id_value) {
      // try from cookie
      click_id_value = getCookie(click_id_param_name);
      if (!click_id_value) {
        console.warn('no click id on conversion page');
        return;
      }
    }
    if (!advertiser_key) {
      console.warn('script does not have advertiser key attribute');
      return;
    }
    var url = "https://api.mobitech-content.xyz/v1/content/advertisers/".concat(advertiser_key, "/post_click?click_id=").concat(click_id_value);
    fetch(url).then(function (res) {
      return console.log('response', res.status + " " + res.statusText);
    });
  }
  function landingPage() {
    if (click_id_param_name) {
      var urlSearchParams = new URLSearchParams(window.location.search);
      // if click id exists in url params, save it to localhost and cookie
      var clickId = urlSearchParams.get(click_id_param_name);
      if (clickId) {
        if (localStorage) {
          // save to local storage
          localStorage.setItem(click_id_param_name, urlSearchParams.get(click_id_param_name));
        }
        setCookie(click_id_param_name, urlSearchParams.get(click_id_param_name), 1);
      } else {
        console.warn('no click id on landing page');
      }
    } else {
      console.warn('script does not have click_id_param_name attribute');
    }
  }
  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
});